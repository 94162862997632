<template>
  <div id="survivalrecord">
    <HeaderBack backto="/survivalkit" />
    <portal to="topbar-text">
      <h2>Survival Kit</h2>
      <p v-if="record != null">{{ record.title }}</p>
    </portal>

    <!--
    <div id="filtersearchitems"">
      <label for="searchItemsField">&#128270;</label>
      <input
        id="searchItemsField"
        type="text"
        name="searchField"
        v-model="searchItemsTerm"
         value=""
        v-bind:class="{ empty: searchItemsTerm == 0 }"
      />
      <button v-on:click="searchItemsTerm = ''">
        &#9003;
      </button>
    </div>
    -->

    <Loading
      class="mx-auto d-block mt-5"
      style="width: 5em;"
      v-if="record == null"
    />
    <div v-else>
      <p id="description" v-bind:class="'bg-' + record.type.color">
        {{ record.description }}
      </p>
      <SurvivalItems v-bind:item="filteredItems" v_bind:index="0" />
    </div>
  </div>
</template>

<script>
import HeaderBack from "@/components/HeaderBack.vue";
import Loading from "@/components/Loading.vue";
import SurvivalItems from "@/components/SurvivalItems.vue";

export default {
  name: "SurvivalKit",
  components: {
    HeaderBack,
    Loading,
    SurvivalItems
  },

  mounted: function() {
    this.$store.dispatch("getSurvivalKits").then(() => {
      console.log("Survival Kit", this.$store.state.survivalKits);
      console.log("Router param", this.$route.params.id);
      this.record = this.$store.state.survivalKits[this.$route.params.id];
      console.log("record", this.record);
    });
  },
  data: function() {
    return {
      /* searchItemsTerm: "", */
      record: null
    };
  },
  methods: {
    recordClicked: function(index) {
      if (this.selectedRecord == index) {
        this.selectedRecord = -1;
        this.searchItemsTerm = "";
      } else {
        this.selectedRecord = index;
      }
    }
  },
  computed: {
    filteredItems: function() {
      /* var vm = this; */
      return this.record.items;
      /* .filter(function(
       *       item
       *     ) {
       *       return (
       *         item.title.toLowerCase().indexOf(vm.searchItemsTerm.toLowerCase()) !==
       *         -1
       *       );
       *     }); */
    }
  }
};
</script>

<style scoped lang="scss">
.square-list-group {
  border-radius: 0 !important;
}

#description {
  @extend .px-5;
  @extend .pb-4;
  @extend .pt-3;
  @extend .mx-3;
  @extend .mb-4;
  @extend .shadow;
  @extend .text-white;
}

#survival {
  #filtersearch,
  #filtersearchitems {
    /* @extend .sticky-top; */
    @extend .d-flex;
    text-align: center;

    label {
      @extend .px-2;
    }
    input {
      @extend .flex-grow-1;
      //      width: 70%;
      & ~ button {
        @extend .btn;
        @extend .btn-light;
        display: inline;
      }
      &.empty {
        //        width: 80%;
        //        margin-left: 1em;
        & ~ button {
          display: none;
        }
      }
    }
  }

  .survival-list-enter,
  .survival-list-leave-to {
    opacity: 0;
  }
  .survival-list-leave-active {
    position: absolute;
  }

  #categories {
    li {
      transition: all 0.5s;
      display: inline-block;
      @extend .p-0;

      a {
        @extend .typelogo;
        @extend .px-0;
        @extend .py-0;
        min-width: 4em;
        text-align: center;
        vertical-align: center;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &:last-of-type {
          /* @extend .px-4; */
          min-width: 4em;
        }

        img {
          /* max-width: 2.5em; */
          max-height: 2.3em;
          /* @extend .w-50; */
          @extend .mx-auto;
        }
      }

      .container {
        @extend .p-0;
        max-width: 100%;
        .row {
          @extend .mx-0;
        }
      }

      &.header {
        @extend .p-0;
        @extend .text-light;
        a {
          @extend .h5;
          @extend .mb-0;
          @extend .text-light;
        }
      }
    }

    .typelogo {
      @extend .rounded;
      @extend .p-2;
      vertical-align: top;
    }
  }
}
</style>
