<template>
  <div>
    <transition-group
      v-if="Array.isArray(item)"
      v-bind:id="'recordcontent' + index"
      class="list-group square-list-group"
      name="survival-list"
      tag="ul"
    >
      <li
        class="list-group-item"
        v-bind:index="id"
        v-for="(it, id) in item"
        v-bind:key="it.title"
      >
        <div class="card">
          <a
            v-bind:id="'heading' + index + id"
            class="collapsed card-header"
            data-toggle="collapse"
            v-bind:data-parent="'#recordcontent' + index"
            v-bind:data-target="'#collapse' + index + id"
            v-bind:aria-expanded="index.length > 0"
            v-bind:aria-controls="'collapse' + index + id"
            v-bind:href="'#heading' + index + id"
          >
            {{ it.title }}
          </a>
          <div
            v-bind:id="'collapse' + index + id"
            class="collapse"
            v-bind:aria-labelledby="'heading' + index + id"
            v-bind:data-parent="'#recordcontent' + index"
          >
            <SurvivalItems
              v-bind:item="it.content"
              v-bind:index="index.toString().concat(id)"
            />
          </div>
        </div>
      </li>
    </transition-group>
    <div v-else class="card-body" v-html="item"></div>
  </div>
</template>

<script>
export default {
  name: "SurvivalItems",
  props: {
    item: {
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped lang="scss">
.list-group-item {
  @extend .p-1;
}
</style>
